import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledVideo = styled.div`
  iframe:focus {
    outline: none;
  }

  iframe[seamless] {
    display: block;
  }

  iframe {
    width: 100%;
    max-width: 768px;
    height: auto;
    border-radius: var(--border-radius);
    background-color: var(--green);
    vertical-align: middle;
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=.2);
    border: 0;
  }
`;
const Video = ({ videoSourceURL, videoTitle }) => (
  <StyledVideo>
    <iframe
      className="custom-video"
      src={videoSourceURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </StyledVideo>
);

Video.propTypes = {
  videoSourceURL: PropTypes.string,
  videoTitle: PropTypes.string,
};

export default Video;
